
import { mapGetters } from "vuex";
import NuxtSSRScreenSize from "nuxt-ssr-screen-size";
import "vue-collapsible-component/lib/vue-collapsible.css";
import Collapsible from "vue-collapsible-component";
import CloseIcon from "@/assets/svg/heroicons/x.svg?inline";
import AngleUp from "@/assets/svg/heroicons/angle-up.svg?inline";
import AngleDown from "@/assets/svg/heroicons/angle-down.svg?inline";
// import Graybg from '~/assets/images/general/Graybg.svg?inline'

export default {
  components: {
    Collapsible,
    CloseIcon,
    AngleUp,
    AngleDown,
    CentroStileBenelli: () =>
      import("~/assets/svg/centro-stile-benelli.svg?inline"),
    Slogan: () => import("~/assets/svg/slogan.svg?inline"),
    FooterLogo: () => import("~/assets/svg/footer-logo.svg?inline"),
    // Graybg
  },
  mixins: [NuxtSSRScreenSize.NuxtSSRScreenSizeMixin],
  data(d) {
    return {
      browserWidth: 0,
      isDisclaimer: false,
      year: new Date().getFullYear(),
      intercom: typeof d.$intercom !== "undefined" ? d.$intercom : null,
    };
  },
  computed: {
    ...mapGetters({
      products: "getProducts",
      sites: "getSites",
      country: "getCountry",
      locales: "locales/getDefaultLocales",
      currentSiteSettings: "getCurrentSiteSettings",
      currentSite: "getCurrentSite",
    }),
    textClass() {
      return this.$store.state.locale === "el"
        ? "text-[17px] 2xl:text-[20px] font-bold"
        : "text-[30px]";
    },
    legals() {
      let disclaimer = [];
      let terms = [];
      if (
        this.currentSite &&
        this.currentSite.disclaimer &&
        this.currentSite.country.continent === "Europe"
      ) {
        disclaimer = [
          {
            link: "disclaimer",
            name: "disclaimer",
          },
        ];
      }
      if (this.$store.state && this.$store.state.country !== "it") {
        terms = [
          {
            link: "terms",
            name: "terms_and_conditions",
          },
        ];
      }
      return [
        ...disclaimer,
        ...terms,
        {
          link: "privacy-policy",
          name: "privacy_policy",
        },
        {
          link: "cookie-policy",
          name: "cookie_policy",
        },
        {
          link: "unsubscribe",
          name: "unsubscribe",
        },
      ];
    },
    countryName() {
      const country = this.locales.find((x) => x.slug === this.country);
      return country ? country.country.name : "";
    },
    checkSocialExist() {
      let data = [
        "facebook",
        "instagram",
        "linkedin",
        "twitter",
        "youtube",
        "tiktok",
      ];
      return data.find((_v) => this.currentSiteSettings.socials[_v]);
    },
  },
  // async beforeCreate() {
  //   try {
  //     const { data } = await this.$axios.$get("api/front/pages/disclaimer");

  //     if (data) {
  //       this.isDisclaimer = true;
  //     }
  //   } catch (e) {
  //     console.error(e);
  //   }
  // },
  mounted() {
    this.browserWidth = this.$vssWidth;
    window.addEventListener("resize", this.windowResized);
    if (process.browser) {
      const collapsible = document.getElementsByClassName(
        "Collapsible__trigger"
      );
      if (collapsible && collapsible[0]) {
        collapsible[0].setAttribute("aria-label", "benelli info");
      }
    }
  },
  destroyed() {
    window.removeEventListener("resize", this.windowResized);
  },
  methods: {
    windowResized() {
      this.browserWidth = this.$vssWidth;
    },
  },
};
