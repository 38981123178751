
export default {
  props: {
    backgroundClass: {
      type: String,
      required: false,
      default: "text-black group-hover:text-white",
    },
    iconClass: {
      type: String,
      required: false,
      default: "text-secondary-light group-hover:text-primary",
    },
  },
};
